<template>
  <list class="breadcrumbs" :class="wrapperClass">
    <list-item>
      <router-link to="/">{{ t("core.home") }}</router-link>
    </list-item>
    <list-item v-for="crumb of crumbs" :key="crumb" :crumb="crumb">
      <router-link v-slot="{ navigate, href, route }" :to="crumb.path" custom>
        <anchor
          v-if="!crumb.isLast"
          type="default"
          :show-slot="false"
          @click="navigate">
          {{ crumb.label }}
        </anchor>
        <inline v-if="crumb.isLast" type="default" :show-slot="false">
          {{ crumb.label }}
        </inline>
      </router-link>
    </list-item>
  </list>
</template>

<script>
import BreadCrumbsMixin from "@/client/extensions/mixins/baseComponents/breadCrumbs.js";

export default {
  mixins: [BreadCrumbsMixin],
  props: {
    size: {
      type: String,
      default: "default",
    },
  },
  data: function () {
    return {};
  },
  computed: {
    wrapperClass() {
      return "breadcrumbs--" + this.size;
    },
  },
  created() {},
};
</script>

<style scoped lang="scss"></style>
